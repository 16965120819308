import { injectIntl } from "gatsby-plugin-intl"
import React, { Fragment } from "react"
import services from "../sources/services"
import "./Servizi.css"

const Servizi = ({ intl }) => (
  <div className="ServiziContainer  ">
    <div className="serviziDetailsContainer">
      {services.map((service, index) => {
        return (
          <Fragment key={index}>
            <h2>
              <b>{intl.formatMessage({ id: `servizi-${service.id}:label` })}</b>
            </h2>
            <ul>
              {service.subservices &&
                service.subservices.map((subservice, index) => {
                  return (
                    <li key={index}>
                      {intl.formatMessage({
                        id: `${service.id}-sottoservizio-${subservice}:label`,
                      })}
                    </li>
                  )
                })}
            </ul>
          </Fragment>
        )
      })}
    </div>
  </div>
)

export default injectIntl(Servizi)
