export default [
  {
    id: "pulizie-civili",
    subservices: [
      "uffici",
      "aziende",
      "pulizia-capannoni",
      "pulizia-fine-cantiere",
    ],
  },
  {
    id: "negozi-supermercati",
    subservices: ["centri-commerciali", "pulizia-banche", "pulizia-negozi"],
  },
  {
    id: "camping-residence",
    subservices: ["di-fondo", "di-inizio", "pulizia-ordinaria"],
  },
  {
    id: "appartamenti-ville",
    subservices: ["pavimenti-di-ogni-tipo", "scale-con-relative-balaustre"],
  },
  {
    id: "condomini",
    subservices: ["scale-ringhiere", "vetrate"],
  },
  {
    id: "fine-cantiere",
    subservices: [
      "utilizzo-acidi",
      "scale-ringhiere",
      "vetrate-ambienti",
      "porte-tapparelle",
      "trattamento-prodotti-idonei",
    ],
  },
  {
    id: "sanificazione",
    subservices: [
      "pulizia-disinfezione-locali-edifici-scolastici",
      "pulizia-disinfezione",
    ],
  },
]
